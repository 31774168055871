<template>
  <v-dialog
    :value="true"
    max-width="400"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 mb-3">Benachrichtigungen</v-card-title>
      <v-card-text>
        <v-divider class="my-4"></v-divider>
        <div v-for="type in types" :key="type">
          <span>{{ $t(type.replace(/-/g, '_')) }}</span>
          <v-row>
            <v-col><v-checkbox :input-value="notify.indexOf(type) > -1" label="In App" dense hide-details @change="setNotify(type, $event)"/></v-col>
            <v-col><v-checkbox :input-value="push.indexOf(type) > -1" :disabled="notify.indexOf(type) === -1" label="Push" dense hide-details @change="setPush(type, $event)"/></v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          :disabled="saving"
          @click="$emit('close')"
        >
          Abbrechen
        </v-btn>
        <v-btn
          color="success"
          text
          :disabled="!dirty"
          :loading="saving"
          @click="save"
        >
          Speichern
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const CHAT_TYPES = ['message-direct', 'message-group']
const POST_TYPES = ['new-post', 'comment-my-post', 'comment-my-comment'] // , mention-me, new-post-review]

export default {
  name: 'NotificationSettingsDlg',
  data () {
    return {
      notify: [],
      push: [],
      saving: false
    }
  },
  computed: {
    types () {
      const types = this.$cfg.chat !== false ? [...CHAT_TYPES] : []
      types.push(...POST_TYPES)
      return types
    },
    dirty () {
      return this.hashkey(this.notify) !== this.hashkey(this.$root.user.notify) ||
        this.hashkey(this.push) !== this.hashkey(this.$root.user.push)
    }
  },
  methods: {
    hashkey (arr) {
      if (!arr || arr.length === 0) return ''
      return arr.sort((a, b) => a < b ? -1 : 1).join(',')
    },
    setNotify (type, value) {
      if (value) {
        this.notify.push(type)
      } else {
        this.notify = this.notify.filter(n => n !== type)
        this.push = this.push.filter(n => n !== type)
      }
    },
    setPush (type, value) {
      if (value) {
        this.push.push(type)
      } else {
        this.push = this.push.filter(n => n !== type)
      }
    },
    async save () {
      this.saving = true
      this.$fb.db.doc('users/' + this.$root.userId).update({
        notify: this.notify,
        push: this.push
      })
      this.saving = false
      this.$emit('close')
    }
  },
  watch: {
    '$root.user': {
      handler (user) {
        this.notify = [...(user.notify || [])]
        this.push = [...(user.push || [])]
      },
      immediate: true
    }
  }
}
</script>
